import React, { useState, useEffect } from "react";
import Header from "./Header";
import { useUser } from "./Context";
import { getUserMovies, getTMDBMovies } from "../api/api";
import Burger from "./Burger";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

const Userpage = () => {
  const { user, dispatch } = useUser();
  const [userMovies, setUserMovies] = useState([]);
  const [movieDetails, setMovieDetails] = useState([]);
  const [attempts, setAttempts] = useState(0);
  const [uniqueMoviesDetails, setUniqueMoviesDetails] = useState([]);
  const [showMessage, setShowMessage] = useState(false);

  const handleGetUserMovies = async () => {
    try {
      const resp = await getUserMovies(user.name);
      if (resp.ok) {
        const response = await resp.json();

        userMovies.push(...response.movies);

        setUserMovies(userMovies);
      }
    } catch (error) {
      if (attempts < 3) {
        setAttempts(attempts + 1);
      }
      console.error("Search movie error:", error);
    }

    const moviesDetails = [];
    try {
      for (let i = 0; i < userMovies.length; i++) {
        const rep = await getTMDBMovies(userMovies[i].tmdb_id);
        if (rep.ok) {
          const movieDetail = await rep.json();
          movieDetail.vote = await searchVote(userMovies[i].tmdb_id);
          moviesDetails.push(movieDetail);
        } else if (rep.status === 404) {
          continue;
        }
      }
    } catch (error) {
      console.error("Searc in TMDB movie error:", error);
    }

    setMovieDetails(moviesDetails);
  };

  const searchVote = async (movieId) => {
    for (let i = 0; i < userMovies.length; i++) {
      if (userMovies[i].tmdb_id === movieId) {
        return userMovies[i].vote;
      }
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowMessage(true);
    }, 2000);

    if (user.movies && user.movies.length === 0) {
      if (attempts < 3) {
        handleGetUserMovies();
      }
    } else {
      setMovieDetails(user.movies);
    }

    return () => clearTimeout(timer);
  }, [attempts]);

  useEffect(() => {
    const uniqueMoviesDetails = Array.from(
      new Set(movieDetails.map((movie) => movie.id))
    ).map((id) => movieDetails.find((movie) => movie.id === id));

    setUniqueMoviesDetails(uniqueMoviesDetails);
    dispatch({ type: "UPDATE_MOVIES", payload: uniqueMoviesDetails });
  }, [movieDetails]);

  return (
    <div id="App">
      <Burger />

      <div className="container" id="page-wrap">
        <Header showArrowLink={false}></Header>
        <div>
          {showMessage && uniqueMoviesDetails.length === 0 && (
            <p>Vous n'avez pas de films.</p>
          )}

          {uniqueMoviesDetails.length > 0 && (
            <div className="movies-list-2">
              {uniqueMoviesDetails.map((movie) => {
                return (
                  <div key={movie.id}>
                    <h3>{movie.title}</h3>
                    {movie.poster_path && (
                      <img
                        alt={""}
                        src={`https://image.tmdb.org/t/p/w500${movie.poster_path}`}
                      />
                    )}
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span>Note globale : {movie.vote_average}&nbsp;</span>
                      <span>
                        <FontAwesomeIcon
                          icon={faStar}
                          style={{ marginRight: 10, color: "gold" }}
                        />
                      </span>
                    </div>
                    <p></p>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span>Votre note : {movie.vote}&nbsp;</span>
                      <span>
                        <FontAwesomeIcon
                          icon={faStar}
                          style={{ marginRight: 10, color: "gold" }}
                        />
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Userpage;
