import React, { useState } from "react";
import { register } from "../api/api";
import Header from "./Header";
import { hashPassword } from "../utils/hash";

const Register = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleRegistration = async () => {
    try {
      const resp = await register(username, hashPassword(password), email);

      if (resp.ok) {
        setSuccessMessage(
          "Votre compte a été créé avec succès " + username + " !"
        );
        setErrorMessage("");
      } else if (resp.status === 409) {
        setErrorMessage("Cet d'utilisateur existe déjà.");
        setSuccessMessage("");
      }
    } catch (error) {
      console.error("Registration Error:", error);
    }
  };

  return (
    <div className="container">
      <Header showArrowLink={true} page={"/"} />
      <h2 className="title">Créer un compte</h2>
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      {successMessage && <p className="success-message">{successMessage}</p>}
      <form className="form">
        <label className="label">
          <br />
          <input
            className="input"
            placeholder="Nom d'utilisateur"
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            autoCapitalize="none"
            required
          />
          <input
            className="input"
            placeholder="Email"
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            autoCapitalize="none"
            required
          />
          <input
            className="input"
            placeholder="Mot de passe"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            autoCapitalize="none"
            required
          />
        </label>
        <br />
        <br />
        <button className="button" type="button" onClick={handleRegistration}>
          Créer un compte
        </button>
      </form>
    </div>
  );
};

export default Register;
