import React, { useEffect, useState } from "react";
import Header from "./Header";
import { useParams } from "react-router-dom";
import CryptoJS from "crypto-js";
import { updatePassword } from "../api/api";
import { hashPassword } from "../utils/hash";

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

const Userupdate = () => {
  let { crypt } = useParams();
  const [username, setUsername] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const decryptUsername = (urlSafeCiphertext) => {
    let ciphertext = decodeURIComponent(urlSafeCiphertext);
    let bytes = CryptoJS.AES.decrypt(ciphertext, SECRET_KEY);
    let originalText = bytes.toString(CryptoJS.enc.Utf8);

    setUsername(originalText);
  };

  const updateUserPassword = async () => {
    if (password1 !== password2) {
      setErrorMessage("Les mots de passe ne correspondent pas.");
      setSuccessMessage("");
      return;
    }

    try {
      const rep = await updatePassword(username, hashPassword(password1));
      if (rep.ok) {
        setSuccessMessage("Mot de passe mis à jour avec succès");
        setErrorMessage("");
      } else if (rep.status === 404) {
        setErrorMessage("L'utilisateur n'existe pas.");
        setSuccessMessage("");
      }
    } catch (error) {
      setErrorMessage(
        "Erreur lors de la mise à jour du mot de passe : " + error.text
      );
      setSuccessMessage("");
    }
  };

  useEffect(() => {
    decryptUsername(crypt);
  }, [crypt]);

  return (
    <div id="App">
      <div className="container" id="page-wrap">
        <Header showArrowLink={true} page={"/"} />
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        {successMessage && <p className="success-message">{successMessage}</p>}
        <form className="form">
          <label className="label">
            <br />
            <input
              className="input"
              placeholder="Mot de passe"
              type="password"
              value={password1}
              onChange={(e) => setPassword1(e.target.value)}
              autoCapitalize="none"
              required
            />
            <input
              className="input"
              placeholder="Confirmer le mot de passe"
              type="password"
              value={password2}
              onChange={(e) => setPassword2(e.target.value)}
              autoCapitalize="none"
              required
            />
          </label>
          <br />
          <br />
          <button className="button" type="button" onClick={updateUserPassword}>
            Modifier le mot de passe
          </button>
        </form>
      </div>
    </div>
  );
};

export default Userupdate;
