// Header.js
import React from "react";
import { Link } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import "../styles/Header.css";

const Header = ({ showArrowLink, page, children }) => {
  return (
    <div className="header">
      {showArrowLink && (
        <Link className="arrow-link" to={page}>
          <FaArrowLeft style={{ fontSize: "24px" }} />
        </Link>
      )}
      <img src="/video-camera.png" alt="Logo" className="logo" />
      CineDimanche
      {children}
    </div>
  );
};

export default Header;
