import React, { useState } from "react";
import Header from "./Header";
import Burger from "./Burger";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { searchMovies } from "../api/api";
import { Link } from "react-router-dom";

const SearchAddMovie = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const handleSearchMovieAPI = async (movieName) => {
    try {
      const resp = await searchMovies(movieName);

      if (resp.ok) {
        const response = await resp.json();
        setSearchResults(response.results);
      }
    } catch (error) {
      console.error("Login Error:", error);
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    setTimeout(() => {
      handleSearchMovieAPI(searchTerm);
    }, 1000);
  };

  return (
    <div id="App">
      <Burger />
      <div className="container" id="page-wrap">
        <Header showArrowLink={false} />
        <div>
          <form className="search-bar" onSubmit={handleSearchSubmit}>
            <input
              type="text"
              placeholder="Rechercher un film"
              value={searchTerm}
              onChange={handleSearchChange}
              autoCapitalize="none"
            />
            <button type="submit">Rechercher</button>
          </form>
          {searchResults.length > 0 && (
            <div className="movies-list">
              {searchResults.map((movie) => {
                return (
                  <Link
                    to={{
                      pathname: `/movie/${movie.id}`,
                    }}
                    className="movie-box"
                    key={movie.id}>
                    <div>
                      <h3>{movie.title}</h3>
                      {movie.poster_path && (
                        <img
                          alt={""}
                          src={`https://image.tmdb.org/t/p/w500${movie.poster_path}`}
                        />
                      )}
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <span>{movie.vote_average}&nbsp;</span>
                        <span>
                          <FontAwesomeIcon
                            icon={faStar}
                            style={{ marginRight: 10, color: "gold" }}
                          />
                        </span>
                      </div>
                    </div>
                  </Link>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchAddMovie;
