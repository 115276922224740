import React from "react";
import Header from "./Header";
import { useUser } from "./Context";
import Burger from "./Burger";

const Homepage = () => {
  const { user } = useUser();

  return (
    <div id="App">
      <Burger />
      <div className="container" id="page-wrap">
        <Header showArrowLink={false} />
        <h2 className="title">
          Bienvenue sur CineDimanche {user ? user.name : "guest"}!
        </h2>
      </div>
    </div>
  );
};

export default Homepage;
