import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./components/Login";
import Home from "./components/Homepage";
import Register from "./components/Register";
import Userpage from "./components/Userpage";
import SearchAddMovie from "./components/SearchAddMovie";
import Userupdate from "./components/Userupdate";
import Userupdatepass from "./components/Userupdatepass";
import Movie from "./components/Movie";
import { Provider } from "./components/Context";

function App() {
  return (
    <Provider>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/home" element={<Home />} />
          <Route path="/register" element={<Register />} />
          <Route path="/user" element={<Userpage />} />
          <Route path="/search" element={<SearchAddMovie />} />
          <Route path="/movie/:id" element={<Movie />} />
          <Route path="/update" element={<Userupdate />} />
          <Route path="/update/:crypt" element={<Userupdatepass />} />
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
