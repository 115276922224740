import React, { useState } from "react";
import Header from "./Header";
import emailjs from "@emailjs/browser";
import { getUser } from "../api/api";
import CryptoJS from "crypto-js";

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
const SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID;
const EMAIL_JS_PUBLIC_KEY = process.env.REACT_APP_EMAIL_JS_PUBLIC_KEY;

const Userupdate = () => {
  const [username, setUsername] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const currentUrl = window.location.href;
  console.log(currentUrl);
  const checkUser = async (e) => {
    e.preventDefault();
    try {
      const resp = await getUser(username);

      if (resp.ok) {
        const response = await resp.json();
        await sendEmail(response.email, username);
      } else if (resp.status === 404) {
        setErrorMessage("L'utilisateur n'existe pas.");
        setSuccessMessage("");
      }
    } catch (error) {
      console.error("Get user error:", error);
    }
  };

  const sendEmail = async (email, username) => {
    let ciphertext = CryptoJS.AES.encrypt(username, SECRET_KEY).toString();
    let urlSafeCiphertext = encodeURIComponent(ciphertext);
    let url = window.location.href + "/" + urlSafeCiphertext;

    emailjs
      .send(
        SERVICE_ID,
        TEMPLATE_ID,
        { user_email: email, user_name: username, url: url },
        EMAIL_JS_PUBLIC_KEY
      )
      .then(
        (result) => {
          setSuccessMessage(
            "Email de modification de mot de passe envoyé avec succès"
          );
          setErrorMessage("");
        },
        (error) => {
          setErrorMessage("Erreur lors de l'envoi de l'email : " + error.text);
          setSuccessMessage("");
        }
      );
  };

  return (
    <div id="App">
      <div className="container" id="page-wrap">
        <Header showArrowLink={true} page={"/"} />
        <form className="search-bar">
          <input
            type="text"
            placeholder="Nom d'utilisateur"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            autoCapitalize="none"
          />
          <button type="submit" onClick={checkUser}>
            Rechercher
          </button>
        </form>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        {successMessage && <p className="success-message">{successMessage}</p>}
      </div>
    </div>
  );
};

export default Userupdate;
