import React from "react";
import { push as Menu } from "react-burger-menu";
import { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useUser } from "./Context";
import {
  faHome,
  faSearch,
  faTimes,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

export default function Burger() {
  const { dispatch } = useUser();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleDisconnect = async () => {
    setIsMenuOpen(false);
    dispatch({ type: "CLEAR_USER" });
  };

  return (
    <Menu
      right
      noOverlay
      pageWrapId={"page-wrap"}
      outerContainerId={"App"}
      width={"45%"}
      isOpen={isMenuOpen}
      onStateChange={({ isOpen }) => setIsMenuOpen(isOpen)}>
      <button
        className="button-close-menu"
        onClick={() => setIsMenuOpen((prevState) => !prevState)}>
        <FontAwesomeIcon icon={faTimes} />
      </button>
      <Link to="/home" onClick={() => setIsMenuOpen(false)}>
        <FontAwesomeIcon icon={faHome} style={{ marginRight: 10 }} />
        Page principale
      </Link>
      <Link to="/user" onClick={() => setIsMenuOpen(false)}>
        <FontAwesomeIcon icon={faUser} style={{ marginRight: 10 }} />
        Vos films
      </Link>
      <Link to="/search" onClick={() => setIsMenuOpen(false)}>
        <FontAwesomeIcon icon={faSearch} style={{ marginRight: 10 }} />
        Rechercher un film
      </Link>
      <div className="logout-button">
        <a
          onClick={() => {
            handleDisconnect();
          }}
          id="logout"
          href="/">
          Se deconnecter
        </a>
      </div>
    </Menu>
  );
}
