import React, { useState } from "react";
import { login } from "../api/api";
import { Link, useNavigate } from "react-router-dom";
import "../styles/Page.css";
import "./Header";
import Header from "./Header";
import { useUser } from "./Context";
import { hashPassword } from "../utils/hash";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const { dispatch } = useUser();

  const handleLogin = async () => {
    try {
      const resp = await login(username, hashPassword(password));

      if (resp.ok) {
        setErrorMessage("");
        const connectResponse = await resp.json();
        if (connectResponse.connected === false) {
          setErrorMessage("Le mot de passe est incorrect.");
          return;
        }
        const userResp = {
          id: connectResponse.userId,
          name: username,
        };
        dispatch({ type: "SET_USER", payload: userResp });
        navigate("/home");
      } else if (resp.status === 404) {
        setErrorMessage("L'utilisateur n'existe pas.");
      }
    } catch (error) {
      console.error("Login Error:", error);
    }
  };

  return (
    <div className="container">
      <Header showArrowLink={false} />
      <h2 className="title">Connection</h2>
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      <form className="form">
        <label className="label">
          <input
            className="input"
            type="text"
            placeholder="Nom d'utilisateur"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            autoCapitalize="none"
            required
          />
          <input
            className="input"
            type="password"
            placeholder="Mot de passe"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            autoCapitalize="none"
            required
          />
        </label>
        <br />
        <br />
        <button className="button" type="button" onClick={handleLogin}>
          Se connecter
        </button>
      </form>
      <br />
      {/* <Link className="register-link" to="/update">
        Mot de passe oublié ?
      </Link> */}
      <Link className="register-link" to="/register">
        Créer un compte
      </Link>
      <Link className="register-link" to="/update">
        Mot de passe oublié ?
      </Link>
    </div>
  );
};

export default Login;
