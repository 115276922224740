import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Header from "./Header";
import { addUserMovie, getTMDBMovies } from "../api/api";
import { useUser } from "./Context";
import ReactStars from "react-rating-stars-component";

function Movie() {
  let { id } = useParams();
  const { user, dispatch } = useUser();
  const [searchResults, setSearchResults] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [rating, setRating] = useState(0);

  const submitRating = async () => {
    if (rating !== null && rating >= 0 && rating <= 10) {
      try {
        const resp = await addUserMovie(id, user.name, rating);
        if (resp.ok) {
          setSuccessMessage("Film ajouté avec succès");
          setErrorMessage("");
          if (user.movies.length > 0) {
            searchResults.vote = rating;
            user.movies.push(searchResults);
            dispatch({ type: "UPDATE_MOVIES", payload: user.movies });
          }
        } else if (resp.status === 409) {
          setErrorMessage("Ce film est déjà dans votre liste");
          setSuccessMessage("");
        } else if (resp.status === 404) {
          setErrorMessage("Utilisateur non trouvé");
          setSuccessMessage("");
        }
      } catch (error) {
        console.error("Add user movie error:", error);
      }
    } else {
      window.alert("Invalid rating. Please enter a number between 0 and 10.");
    }
  };

  const handleGetMovieAPI = async (movieId) => {
    try {
      const resp = await getTMDBMovies(movieId);
      if (resp.ok) {
        const response = await resp.json();
        setSearchResults(response);
      }
    } catch (error) {
      console.error("Get movie error:", error);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      handleGetMovieAPI(id);
    }, 1000);
  }, [id]);

  return (
    <div id="App">
      <div className="container" id="page-wrap">
        <Header showArrowLink={true} page={"/search"} />
        {Object.keys(searchResults).length > 0 > 0 && (
          <div className="movie-details">
            <h3>{searchResults.title}</h3>
            <p>{searchResults.overview}</p>
            <img
              src={`https://image.tmdb.org/t/p/w500${searchResults.poster_path}`}
              alt={searchResults.title}
            />
            <h2 style={{ marginTop: "5px" }}>
              Date : {searchResults.release_date}
            </h2>
            <div>
              <div className="rating">
                <ReactStars
                  name="rate"
                  size={27}
                  classNames={"rating-stars"}
                  count={10}
                  onChange={setRating}
                />
                <p
                  className="rating-text"
                  style={{
                    position: "relative",
                    textAlign: "center",
                    fontSize: "30px",
                    color: "gold",
                    marginLeft: "8px",
                    margin: 0,
                    padding: 5,
                    bottom: -5,
                  }}>
                  {rating}
                </p>
              </div>
              <button className="submit-button" onClick={submitRating}>
                Ajouter ce film
              </button>
            </div>
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            {successMessage && (
              <p className="success-message">{successMessage}</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default Movie;
