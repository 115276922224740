const REACT_APP_BASE_URL = process.env.REACT_APP_AWS_BASE_URL;
const REACT_APP_TMDB_BASE_URL = process.env.REACT_APP_TMDB_BASE_URL;
const REACT_APP_API_KEY = process.env.REACT_APP_AWS_API_KEY;
const REACT_APP_TMDB_BEARER = process.env.REACT_APP_TMDB_BEARER;

const headersAWSAPI = new Headers();
headersAWSAPI.append("Content-Type", "application/json");
headersAWSAPI.append("X-API-KEY", REACT_APP_API_KEY);

const headersTMDBAPI = new Headers();
headersTMDBAPI.append("Authorization", "Bearer " + REACT_APP_TMDB_BEARER);

const handleResponse = async (response) => {
  if (!response.ok && Math.round(response.status / 100) !== 4) {
    const error = await response.text();
    throw new Error(error);
  }

  return response;
};

export const login = async (username, password) => {
  try {
    const response = await fetch(`${REACT_APP_BASE_URL}/user/connect`, {
      method: "POST",
      body: JSON.stringify({ name: username, pass: password }),
      headers: headersAWSAPI,
    });
    return handleResponse(response);
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
};

export const register = async (username, password, email) => {
  try {
    const response = await fetch(`${REACT_APP_BASE_URL}/user`, {
      method: "POST",
      body: JSON.stringify({ name: username, pass: password, email: email }),
      headers: headersAWSAPI,
    });
    return handleResponse(response);
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
};

export const searchMovies = async (query) => {
  try {
    const response = await fetch(
      `${REACT_APP_TMDB_BASE_URL}/search/movie?query=${query}`,
      {
        method: "GET",
        headers: headersTMDBAPI,
      }
    );
    return handleResponse(response);
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
};

export const getUserMovies = async (userName) => {
  try {
    const response = await fetch(
      `${REACT_APP_BASE_URL}/user/${userName}/movies`,
      {
        method: "GET",
        headers: headersAWSAPI,
      }
    );
    return handleResponse(response);
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
};

export const getTMDBMovies = async (movieId) => {
  try {
    const response = await fetch(
      `${REACT_APP_TMDB_BASE_URL}/movie/${movieId}`,
      {
        method: "GET",
        headers: headersTMDBAPI,
      }
    );
    return handleResponse(response);
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
};

export const addUserMovie = async (movieId, userName, rating) => {
  try {
    movieId = parseInt(movieId);
    rating = parseInt(rating);
    const response = await fetch(`${REACT_APP_BASE_URL}/movie`, {
      method: "POST",
      body: JSON.stringify({
        tmdbId: movieId,
        userName: userName,
        vote: rating,
      }),
      headers: headersAWSAPI,
    });
    return handleResponse(response);
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
};

export const updatePassword = async (username, password) => {
  try {
    const response = await fetch(`${REACT_APP_BASE_URL}/user/${username}`, {
      method: "PUT",
      body: JSON.stringify({ pass: password }),
      headers: headersAWSAPI,
    });
    return handleResponse(response);
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
};

export const getUser = async (username) => {
  try {
    const response = await fetch(
      `${REACT_APP_BASE_URL}/user?name=${username}`,
      {
        method: "GET",
        headers: headersAWSAPI,
      }
    );
    return handleResponse(response);
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
};
