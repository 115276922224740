import React, { useEffect } from "react";
import { createContext, useContext, useReducer } from "react";

const Context = createContext();

export const Provider = ({ children }) => {
  const [user, dispatch] = useReducer(
    userReducer,
    JSON.parse(localStorage.getItem("user")) || null
  );

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(user));
  }, [user]);

  return (
    <Context.Provider value={{ user, dispatch }}>{children}</Context.Provider>
  );
};

export const useUser = () => {
  const context = useContext(Context);
  if (!context) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};

const userReducer = (state, action) => {
  switch (action.type) {
    case "SET_USER":
      return { ...action.payload, movies: [] };
    case "CLEAR_USER":
      localStorage.removeItem("user");
      return null;
    case "UPDATE_MOVIES":
      return { ...state, movies: action.payload };
    default:
      return state;
  }
};
